import styled from 'styled-components'

interface BotaoFiltroProps {
  onClick: () => void
  selecionado: boolean
  texto: string
}

export const BotaoFiltro: React.FC<BotaoFiltroProps> = props => {
  return (
    <Container selecionado={props.selecionado} onClick={props.onClick}>
      <p>{props.texto}</p>
    </Container>
  )
}

const Container = styled.div<{ selecionado: boolean }>`
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: ${props => (props.selecionado ? 'var(--cor-primaria)' : 'var(--cinza95)')};
  color: ${props => (props.selecionado ? 'var(--branco)' : 'var(--preto)')};
  border-radius: 5rem;
  box-shadow: 0 0.25rem 0.25rem var(--cor-sombra);

  p {
    font-weight: 600;
    font-size: 0.8rem;
    text-align: center;
    white-space: nowrap;
  }
`
