import { GetServerSideProps } from 'next'
import { useEffect } from 'react'
import styled from 'styled-components'
import { mutate } from 'swr'

import { Cabecalho } from '@/components/Cabecalho'
import { CampoPesquisa } from '@/components/CampoPesquisa'
import { LinhaPesquisasPopulares, PropsItemPesquisaPopular } from '@/components/LinhaPesquisasPopulares'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import { PageLayout } from '@/components/PageLayout'
import { BotaoFiltro } from '@/components/botoes/BotaoFiltro'
import { CardItemCatalogo, ProdutoCatalogo } from '@/components/containers/CardItemCatalogo'
import { COOKIE_LOJA_MED, COOKIE_URL_LOJA_MED, SS_SCROLL_CATALOGO } from '@/databases/local'
import { useAuth } from '@/hooks/useAuth'
import { PropsFiltro, useCatalogo } from '@/hooks/useCatalogo'
import { api } from '@/services/api'

interface PropsHome {
  filtros: PropsFiltro[]
  produtos: ProdutoCatalogo[]
  pesquisasPopulares: PropsItemPesquisaPopular[]
}

export default function Home(props: PropsHome): JSX.Element {
  const { alterarFiltro, carregando, data, filtroSelecionado, loaderRef, useEffectCompartilhado } = useCatalogo()
  const { user } = useAuth()

  useEffect(() => {
    const posicaoCatalogo = parseInt(sessionStorage.getItem(SS_SCROLL_CATALOGO) || '0')
    window.scrollTo(0, posicaoCatalogo)

    const scroll = () => {
      sessionStorage.setItem(SS_SCROLL_CATALOGO, window.scrollY.toString())
    }

    window.addEventListener('scroll', scroll)

    const parametros = new URLSearchParams()
    parametros.set('filtro', '')
    parametros.set('pagina', '1')
    mutate(`/api_meulook/produtos/catalogo?${parametros}`, props.produtos)

    return () => {
      window.removeEventListener('scroll', scroll)
    }
  }, [])

  useEffect(props.produtos && props.produtos?.length > 0 ? useEffectCompartilhado : () => undefined, [props.produtos])

  return (
    <>
      <Cabecalho esquerda={user?.permissao === 'LOJA' ? ['CONFIGURACOES'] : []} direita={['COMPARTILHAR']} />
      <CampoPesquisa />
      <PageLayout>
        <ContainerFiltros>
          {props.filtros?.map(filtro => (
            <BotaoFiltro
              selecionado={filtroSelecionado === filtro.id}
              texto={filtro.nome}
              onClick={() => alterarFiltro(filtro.id)}
              key={filtro.id}
            />
          ))}
        </ContainerFiltros>
        <br />
        <LinhaPesquisasPopulares pesquisasPopulares={props.pesquisasPopulares} />
      </PageLayout>
      <>
        {data?.map((pagina, paginaIndex) => {
          return (
            <div key={`pagina_${paginaIndex}`}>
              {filtroSelecionado === 'PROMOCAO' && pagina.length > 0 && paginaIndex === 0 && (
                <ContainerTituloPromocaoTemporaria>
                  <h2 className="pulsante">Promoções Temporárias</h2>
                  <p>
                    <b>Aproveite antes que acabe!</b>
                  </p>
                </ContainerTituloPromocaoTemporaria>
              )}
              {filtroSelecionado === 'PROMOCAO' && data[0].length > 0 && paginaIndex === 1 && (
                <ContainerTituloPromocaoTemporaria className="divisor">
                  <h2>Mais Promoções</h2>
                </ContainerTituloPromocaoTemporaria>
              )}
              <ContainerProdutosCatalogo>
                {pagina?.map((produto, produtoIndex) => {
                  return <CardItemCatalogo produto={produto} key={`publicacao_${paginaIndex}_${produtoIndex}`} />
                })}
              </ContainerProdutosCatalogo>
            </div>
          )
        })}
      </>
      <ContainerLoading ref={loaderRef}>{carregando && <LoadingSpinner />}</ContainerLoading>
      <br />
    </>
  )
}

const ContainerFiltros = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0.5rem 1rem;
  overflow: auto;
`

export const ContainerProdutosCatalogo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  padding: 0.5rem;

  // Tablets
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  // Desktop HD
  @media (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }

  // Desktop Full HD
  @media (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }

  // Desktop TOP
  @media (min-width: 2400px) {
    grid-template-columns: repeat(10, 1fr);
  }
`

const ContainerLoading = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  width: 100%;
`

const ContainerTituloPromocaoTemporaria = styled.div`
  padding: 1rem 0;
  text-align: center;
  h2 {
    color: var(--cor-secundaria);
    &.pulsante {
      text-transform: uppercase;
      animation: pulsando 3s ease-in-out 0s infinite both;
      @keyframes pulsando {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.05);
          color: var(--alerta100);
        }
      }
    }
  }
  p {
    margin-top: 0.5rem;
  }
  &.divisor {
    padding: 1.5rem 0;
  }
`

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
  const urlLoja = req.cookies[COOKIE_URL_LOJA_MED] || req.headers.host?.split(':')[0]
  api.defaults.headers.Loja = urlLoja
  let filtros: PropsFiltro[] = []
  let produtos: ProdutoCatalogo[] = []
  let pesquisasPopulares: PropsItemPesquisaPopular[] = []
  try {
    const resposta = await Promise.all([
      api.get<PropsFiltro[]>('api_meulook/publicacoes/filtros'),
      api.get<ProdutoCatalogo[]>('api_meulook/produtos/catalogo'),
      api.get<PropsItemPesquisaPopular[]>('pesquisas_populares')
    ])
    filtros = resposta[0].data
    produtos = resposta[1].data
    pesquisasPopulares = resposta[2].data

    const configuracoesLoja = req.cookies[COOKIE_LOJA_MED]
    const novasConfigsLoja = resposta[0].headers['med-loja']
    if (configuracoesLoja !== novasConfigsLoja) {
      res.setHeader('Set-Cookie', `${COOKIE_LOJA_MED}=${novasConfigsLoja}; path=/;`)
    }
  } catch (error) {}
  return {
    props: {
      filtros,
      produtos,
      pesquisasPopulares
    }
  }
}
