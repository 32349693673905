import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'

import { FotoRedonda } from '../../components/FotoRedonda'
import { usePesquisa } from '../../hooks/usePesquisa'

export interface PropsItemPesquisaPopular {
  nome: string
  foto: string
}

export function LinhaPesquisasPopulares({
  pesquisasPopulares
}: {
  pesquisasPopulares: PropsItemPesquisaPopular[]
}): JSX.Element {
  const router = useRouter()
  const pesquisaContext = usePesquisa()

  const pesquisar = (pesquisa: string) => {
    pesquisaContext.setPesquisa(pesquisa)
    router.push('/pesquisa/resultados')
  }

  return (
    <Container>
      {pesquisasPopulares?.map((pesquisa, index) => {
        return (
          <li key={index} onClick={() => pesquisar(pesquisa.nome)}>
            <figure>
              <FotoRedonda src={pesquisa.foto} />
            </figure>
            <p>{pesquisa.nome}</p>
          </li>
        )
      })}
    </Container>
  )
}

const Container = styled.ul`
  list-style: none;
  display: flex;
  overflow: auto;
  padding: 0 0.5rem 1rem;
  gap: 0.7rem;
  li {
    width: 5rem;
    cursor: pointer;
    figure {
      margin: 0 auto;
      width: 5rem;
      height: 5rem;
      img {
        width: 100%;
        height: 100%;
        box-shadow: 0 0.125rem 0.25rem var(--cor-sombra);
      }
    }
    p {
      font-size: 0.75rem;
      text-align: center;
      margin-top: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }
  }
`
