import { Autocomplete, AutocompleteRenderInputParams, AutocompleteRenderOptionState } from '@mui/material'
import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'
import React, { HTMLAttributes, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { AiOutlineHistory } from 'react-icons/ai'
import { FiSearch } from 'react-icons/fi'
import styled from 'styled-components'

import { LS_HISTORICO_PESQUISA } from '@/databases/local'
import { usePesquisa } from '@/hooks/usePesquisa'
import { api } from '@/services/api'

import { PageLayout } from '../PageLayout'
import { Botao } from '../botoes/Botao'

interface CampoPesquisaProps {
  callbackPesquisar?: (texto: string) => void
}

export const CampoPesquisa: React.FC<CampoPesquisaProps> = props => {
  const { setPesquisa, parametrosDefinidos } = usePesquisa()
  const [carregando, setCarregando] = useState<boolean>(false)
  const [focused, setFocused] = useState<boolean>(false)
  const [opcoes, setOpcoes] = useState<string[]>([])
  const [valorSelecionado, setValorSelecionado] = useState<string>(parametrosDefinidos?.pesquisa)
  const [timer, setTimer] = useState<NodeJS.Timeout>()
  const { enqueueSnackbar } = useSnackbar()
  const quantidadeOpcoes = useRef<number>(0)
  const router = useRouter()

  useEffect(() => {
    if (!valorSelecionado || carregando) return

    clearTimeout(timer)
    const timerAux = setTimeout(() => pesquisarAutoComplete(valorSelecionado), 500)
    setTimer(timerAux)
  }, [valorSelecionado])

  useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0]
    if (focused) bodyElement.style.overflow = 'hidden'
    else bodyElement.style.overflow = 'auto'
  }, [focused])

  async function pesquisarAutoComplete(texto: string): Promise<void> {
    try {
      if (!texto.trim() || texto.match(/[^A-z0-9\s]|\\/)) return

      const parametros = new URLSearchParams({
        pesquisa: texto.trim()
      })
      const response = await api.get<string[]>(`pesquisa/autocomplete?${parametros}`)
      const historicoSugestoes = buscaHistoricoPesquisa()
        .filter((sugestao: string) => !!sugestao.match(new RegExp(`^${texto}`)))
        .slice(0, 4)
      quantidadeOpcoes.current = historicoSugestoes.length
      const sugestoes = response.data.filter((sugestao: string) => !historicoSugestoes.includes(sugestao))
      setOpcoes([...historicoSugestoes, ...sugestoes])
    } catch (error) {
      enqueueSnackbar('Erro ao buscar autocomplete', { variant: 'error' })
    }
  }

  async function pesquisar(texto: string): Promise<void> {
    if (!texto) return

    const textoTratado = texto.trim().toLowerCase()
    if (!buscaHistoricoPesquisa().includes(textoTratado)) {
      await api.post<void>('pesquisa/autocomplete/telemetria', { pesquisa: textoTratado })
      salvarHistoricoPesquisa(textoTratado)
    }
    if (props?.callbackPesquisar) {
      setCarregando(false)
      props.callbackPesquisar(textoTratado)
      return setPesquisa(textoTratado)
    }

    setPesquisa(textoTratado)
    if (router.route === '/pesquisa/resultados') return router.reload()

    router.push('/pesquisa/resultados')
  }

  function buscaHistoricoPesquisa(): string[] {
    return JSON.parse(localStorage.getItem(LS_HISTORICO_PESQUISA) || '[]')
  }

  function salvarHistoricoPesquisa(texto: string): void {
    const novoHistorico = [texto, ...buscaHistoricoPesquisa()]
    localStorage.setItem(LS_HISTORICO_PESQUISA, JSON.stringify(novoHistorico))
  }

  function renderizarInput(parametros: AutocompleteRenderInputParams): JSX.Element {
    return (
      <ContainerRenderizarInput ref={parametros.InputProps.ref}>
        <input
          placeholder="Nome e/ou tamanho do produto"
          type="text"
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && !!valorSelecionado) pesquisar(valorSelecionado)
          }}
          {...parametros.inputProps}
        />
      </ContainerRenderizarInput>
    )
  }

  function renderizarOpcoes(
    props: HTMLAttributes<HTMLLIElement>,
    opcao: string,
    state: AutocompleteRenderOptionState
  ): JSX.Element {
    return (
      <ContainerRenderizarOpcoes
        {...props}
        onClick={evento => {
          props.onClick?.(evento)
          setCarregando(true)
          pesquisar(opcao)
        }}
      >
        {state.index < quantidadeOpcoes.current && <AiOutlineHistory className="icone" />}
        <span>{opcao}</span>
      </ContainerRenderizarOpcoes>
    )
  }

  return (
    <ContainerCampoPesquisa>
      {focused && <div className="container-background" />}
      <ContainerAutocomplete>
        <Autocomplete
          blurOnSelect
          freeSolo
          className="autocomplete"
          loadingText="Buscando Produtos..."
          noOptionsText="Sem sugestões"
          inputValue={valorSelecionado || ''}
          loading={carregando}
          options={opcoes}
          renderInput={renderizarInput}
          renderOption={renderizarOpcoes}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          onInputChange={(_event, novoValor) => setValorSelecionado(novoValor)}
        />
        <div className="container-botao-pesquisar">
          <BotaoPesquisa onClick={() => pesquisar(valorSelecionado)}>
            <FiSearch />
          </BotaoPesquisa>
        </div>
      </ContainerAutocomplete>
    </ContainerCampoPesquisa>
  )
}

const ContainerCampoPesquisa = styled.div`
  height: 3rem;
  width: 100%;
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  position: sticky;
  top: 4.5rem;
  z-index: 3;

  .container-background {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    background-color: var(--preto);
    opacity: 0.4;
  }
`

const ContainerAutocomplete = styled(PageLayout)`
  display: flex;
  border-radius: 7px;
  box-shadow: 0 0.25rem 0.25rem var(--cor-sombra);
  overflow: hidden;

  .autocomplete {
    flex: 1;
  }

  .container-botao-pesquisar {
    height: 100%;
    width: 4rem;
    position: relative;

    button {
      position: absolute;
      z-index: 3;
      height: 100%;
      width: 100%;
      padding: 0.6rem;
      border: none;
      background-color: var(--cor-secundaria);
      color: var(--branco);
    }
  }
`

const BotaoPesquisa = styled(Botao)`
  position: absolute;
  z-index: 3;
  height: 100%;
  width: 100%;
  padding: 0.6rem;
  border: none;
  background-color: var(--cor-secundaria);
  color: var(--branco);
`

const ContainerRenderizarInput = styled.div`
  z-index: 3;
  height: 100%;
  width: 100%;
  position: relative;

  input {
    position: absolute;
    z-index: 3;
    height: 100%;
    width: 100%;
    border: none;
    padding: 0 8px;
  }
`

const ContainerRenderizarOpcoes = styled.li`
  width: 100%;
  padding: 0.5rem 1rem;
  overflow: hidden;

  .icone {
    margin-right: 0.5rem;
  }

  span {
    white-space: nowrap;
  }
`
