import { ImgHTMLAttributes } from 'react'
import styled from 'styled-components'

import { LazyImage } from '../LazyImage'

export const FotoRedonda: React.FC<ImgHTMLAttributes<HTMLImageElement>> = props => <Imagem {...props} />

const Imagem = styled(LazyImage)`
  border-radius: 50%;
`
